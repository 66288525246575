<template>
    <footer>
        <div class="container">
            <div class="row contact-sec">
                <div class="col-md-5 col-lg-5" style="padding-top: 2rem;">
                    <span class="logo">
                         <img src="/img/logo.svg" alt="" width="200px" height="90px">
                    </span>
                    <div class="row" style="padding-bottom: 2rem">
                        <div class="col-sm-6">
                            <ul>
                                <li>
                                    <a href="#"
                                        ><i class="fa fa-phone"></i> +254 207
                                        867 435</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        ><i class="ti-email"></i>
                                        info@omnisure.co.ke</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-lg-5 col-md-offset-2 col-lg-offset-2">
                    <div class="row">
                        <div class="col-sm-6">
                            <h2>Call us</h2>
                        </div>
                    </div>
                    <router-link to="/contact-us" class="btn-default"
                        >Contact Us</router-link
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-lg-8">
                    <ul class="footer-nav">
                        <li> <router-link to="/">Home</router-link></li>
                        <li> <router-link to="/about-us">About Us</router-link></li>
                        <li><router-link to="/services">Services</router-link></li>
                        <li><router-link to="/our-products">Products</router-link></li>
                        <li><router-link to="/contact-us">Contacts</router-link></li>
                        <li><router-link to="/faq">FAQ</router-link></li>
                    </ul>
                </div>
                <div class="col-md-2 col-lg-2 col-md-offset-2 col-lg-offset-2">
                    <ul class="footer-social">
                        <li>
                            <a href="https://www.linkedin.com/in/omnisure-agency-495901210/" title="LinkedIn"><i class="fa fa-linkedin-square"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        Copyright &copy; {{ date.getFullYear() }}
                        <a href="#">www.omnisure.co.ke</a>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4 text-right">
                        <a href="#">Terms & Conditions</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
	export default {
		name: "FooterComponent",

		data() {
			return {
				date: new Date(),
			};
		},
	};
</script>

<style scoped>
 .logo {
     /* background-color: white; */
     padding-top: 45px;
     padding-bottom: 45px;
     padding-left: 1px;
     padding-right: 10px;
 }
</style>
