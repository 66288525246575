import VueRouter from 'vue-router'
import Vue from 'vue'
import HomeComponent from './components/pages/HomeComponent'
import ContactsComponent from './components/pages/ContactsComponent'
import FaqComponent from './components/pages/FaqComponent'
import AboutUsComponent from './components/pages/AboutUsComponent'
import ProductsComponent from './components/pages/ProductsComponent'
import MotorComponent from './components/pages/MotorComponent'
import DomesticComponent from './components/pages/DomesticComponent'
import FireComponent from './components/pages/FireComponent'
import BurglaryComponent from './components/pages/BurglaryComponent'
import WibaComponent from './components/pages/WibaComponent'
import EmployersLiabilityComponent from './components/pages/EmployersLiabilityComponent'
import PublicLiabilityComponent from './components/pages/PublicLiabilityComponent'
import TravelComponent from './components/pages/TravelComponent'
import TradeComponent from './components/pages/TradeComponent'
import ProfessionalComponent from './components/pages/ProfessionalComponent'
import ContractorComponent from './components/pages/ContractorComponent'
import HealthComponent from "@/components/pages/HealthComponent";
import FidelityComponent from "@/components/pages/FidelityComponent";
import PersonalAccidentComponent from "@/components/pages/PersonalAccidentComponent";
import ServicesComponents from "@/components/pages/ServicesComponents";
import QuoteComponent from "@/components/pages/QuoteComponent";
import NotFoundComponent from "@/components/pages/NotFoundComponent";
Vue.use(VueRouter)
const router = new VueRouter ({
    mode : 'history',
	routes : [
		{
			path : '/',
			component : HomeComponent
		},
		{
			path : '/contact-us',
			component : ContactsComponent
		},
		{
			path : '/faq',
			component : FaqComponent
		},
		{
			path : '/about-us',
			name : 'about-us',
			component : AboutUsComponent
		},
		{
			path : '/personal',
		},
		{
			path : '/medical',
		},
		{
			path : '/business',
		},
		{
			path : '/our-products',
			component : ProductsComponent
		},
		{
			path : '/motors',
			component : MotorComponent
		},
		{
			path : '/domestic',
			component : DomesticComponent
		},
		{
			path : '/fire-and-peril',
			component : FireComponent
		},
		{
			path : '/burglary-and-theft',
			component : BurglaryComponent
		},
		{
			path : '/wiba',
			component : WibaComponent
		},
		{
			path : '/employers-liability',
			component : EmployersLiabilityComponent
		},
		{
			path : '/public-liability',
			component : PublicLiabilityComponent
		},
		{
			path : '/travel-package',
			component : TravelComponent
		},
		{
		path : '/trade',
			component : TradeComponent
		},
		{
			path : '/professional-indemnity',
			component : ProfessionalComponent
		},
		{
			path : '/contractor-all-risk',
			component : ContractorComponent
		},
		{
			path : '/health-insurance',
			component : HealthComponent
		},
		{
			path : '/fidelity-guarantee',
			component : FidelityComponent
		},
		{
			path : '/personal-accident',
			component : PersonalAccidentComponent
		},
		{
			path : '/services',
			component : ServicesComponents
		},
		{
			path : '*',
			component : NotFoundComponent
		},
		{
			path : '/get-Quote',
			name : 'QuoteComponent',
			component : QuoteComponent,
			props : true
		}
	],
	scrollBehavior: function(to) {
		if (to.hash) {
			return {selector: to.hash}
		} else {
			return {x: 0, y: 0}
		}
	}
})
export default router;
