<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Fidelity Guarantee</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-shield"></i></span>
									Fidelity Guarantee
								</h4>
								<p>
									The policy covers the financial loss by the insured following any act of fraud or dishonesty committed by an
									Insured Employee acting alone or in collusion with others provided the act is discovered with the specified discovery period.
									The Financial loss shall be sustained within the policy period and during the uninterrupted continuance of employment
									of such an employee and within six months after expiry or six months after termination of such employment whichever shall first happen.
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/inspection-of-building-concept-real-estate-developer-earthquake.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Fidelity Guarantee</h2>
							<h4 style="padding-top: 20px">Exclusions.</h4>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Any losses discovered later than the discovery period covered in the Policy.</li>
										<li><span class="fa fa-chevron-right"></span>Any acts of fraud or dishonesty committed by any employee not declared in the Policy</li>
										<li><span class="fa fa-chevron-right"></span> Any acts of fraud or dishonesty committed by an employee not on permanent terms of service.</li>
										<li><span class="fa fa-chevron-right"></span> Any loss caused by an employee appointed with the Insured’s prior knowledge</li>
										<li><span class="fa fa-chevron-right"></span> Any acts of theft, fraud or dishonesty committed by the employee before employment.</li>
										<li><span class="fa fa-chevron-right"></span> Losses caused by any commission agent, consignee, contractor or representative of the Insured.</li>
										<li><span class="fa fa-chevron-right"></span> Any losses caused by the Insured, director, trustee, partner or any person acting on their behalf.</li>
										<li><span class="fa fa-chevron-right"></span> Consequential loss of any kind.</li>
										<li><span class="fa fa-chevron-right"></span>Any unexplained losses.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: "FidelityComponent",
		components : { MainComponent, BreadCrumb },
		data() {
			return {
				cover : "Fidelity Guarantee Insurance"
			}
		}
	}
</script>

<style scoped>

</style>
