<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Products</bread-crumb>
			<section class="services service-listing">
				<h2 class="text-center">Personal Insurance</h2>
				<div class="container">
					<div class="row">
						<div class="col-sm-3 services-dtl">
							<span class="fa ti-car"></span>
							<h3>Motor Insurance</h3>
							<p>Covers available for vehicles are private vehicles, commercial vehicles and motocycles.</p>
							<router-link to="/motors">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-home"></span>
							<h3>Domestic Package</h3>
							<p>This is a bouquet product designed to cover your domestic residence and household items.</p>
							<router-link to="/domestic">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-heartbeat"></span>
							<h3>Health Insurance</h3>
							<p>
								Our services focus on all-inclusive healthcare solutions and support. We also offer specialised consulting services for all clients as below
							</p>
							<router-link to="/health-insurance">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-warning"></span>
							<h3>Personal Accident</h3>
							<p>
								Covers insured sustaining bodily injury caused solely and directly by accidental, violent or visible means which injury result in disablement or death.
							</p>
							<router-link to="/personal-accident">More</router-link>
						</div>
					</div>
					<div class="row last-row">
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-plane"></span>
							<h3>Travel Insurance</h3>
							<p>This provides 24 hr emergency and medical assistance for travelers anywhere in the world.</p>
							<router-link to="/travel-package">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-briefcase"></span>
							<h3>Professional Indemnity</h3>
							<p>
								This policy protects the insured against liability for professional negligence arising out of their omision or commission.
							</p>
							<router-link to="/professional-indemnity" id="products">More</router-link>
						</div>
					</div>
				</div>
			</section>
			<section class="services service-listing">
				<h2 class="text-center">Business Insurance</h2>
				<div class="container">
					<div class="row">
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-fire"></span>
							<h3>Fire and Perils</h3>
							<p>This policy provides indemnity for loss of or damage to the Insured person’s commercial property resulting from fire.</p>
							<router-link to="/fire-and-peril">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-user-secret"></span>
							<h3>Burglary and Theft</h3>
							<p>This cover compensates you for loss of property arising out of theft housebreaking or burglary from your premises</p>
							<router-link to="/burglary-and-theft">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-wheelchair"></span>
							<h3>Work Injury Benefits -WIBA</h3>
							<p>Covers aganist bodily injury accident or disease to employees arising out of or in the course of employment.</p>
							<router-link to="/wiba">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-users"></span>
							<h3>Employer's Liability</h3>
							<p>Protects employers for their legal liability, for damages and claimant’s costs or expenses of lawsuits filed by an employee</p>
							<router-link to="/employers-liability">More</router-link>
						</div>
					</div>
					<div class="row last-row">
						<div class="col-sm-3 services-dtl">
							<span class="ti-book"></span>
							<h3>Public Liability</h3>
							<p>Protects you against legal liability arising out of claims by third parties for property damage or personal injury</p>
							<router-link to="/public-liability">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-line-chart"></span>
							<h3>Trade Credit insurance</h3>
							<p>This an effective instrument to transfer risks inherent in trade receivables, while outsourcing credit risk management.</p>
							<router-link to="/trade">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-cog"></span>
							<h3>Contractor's All Risks</h3>
							<p>
								This covers a contractor against damage to the contract works, plant & machinery, materials and other related risks
							</p>
							<router-link to="/contractor-all-risk">More</router-link>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-shield"></span>
							<h3>Fidelity Guarantee</h3>
							<p>
								Covers the financial loss by the insured following any act of fraud or dishonesty committed by an Insured Employee
							</p>
							<router-link to="/fidelity-guarantee">More</router-link>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		components : { MainComponent, BreadCrumb },
		name: 'ProductsComponent',

		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>
