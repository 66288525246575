<template>
	<div>
		<div v-for="(som, index) in frequent" :key="index">
		<span class="accordion-section-title">{{ Object.keys(frequent)[0].toUpperCase() }}</span>
			<div v-for="(item, index) in som" :key="index">
				<div class="accordion-section-title">
					<a href="" :class="{ active: item.active }" @click.prevent="toggle(item)">
						<span class="blue-text"> - {{ item.question }}</span>
						<i v-if="item.active" class="fa fa-caret-down"></i>
						<i v-else class="fa fa-caret-right"></i>
					</a>
				</div>
				<div class="accordion-section-content" :class="{ open: item.active }" :style="{ display: item.active ? 'block' : 'none' }">
					<p>{{ item.answer }}</p>
					<ul class="caption-list" v-if="item.lists">
						<li v-for="(list, index) in item.lists" :key="index">
							<i class="glyphicon glyphicon-ok-sign blue-text"></i>
							{{ list }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Question",
		props: ["faq", "faqs", "isSelected"],
		data() {
			return {
				frequent: this.faq,
				// accordion: `accordion-${this.faq.id}`,
			};
		},
		methods: {
			toggle(item) {
				this.$emit("selected", item.id);
			},
		},
	};
</script>

