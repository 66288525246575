<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Work Injury Benifit Act (WIBA)</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-wheelchair"></i></span>
									Work Injury Benifit Act (WIBA)
								</h4>
								<p>
									This policy provides indemnity if the insured employee in the Insured’s immediate service sustain bodily injury by accident or disease arising out of and in the course of employment by the Insured in the Business.
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/dangerous-accident-during-work-first-aid.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h2>Work Injury Benifit Act (WIBA)</h2>
							<p>
								This cover the following:
							</p>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Death: 96 Months earnings</li>
										<li><span class="fa fa-chevron-right"></span>Permanent Total Disability: 96 months earnings</li>
										<li><span class="fa fa-chevron-right"></span>Medical Expenses: Kshs.100,000/= per person</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Funeral Expenses: Kshs.100,000/= per person</li>
										<li><span class="fa fa-chevron-right"></span>Temporary Total Disability: Actual Weekly earnings a maximum of 52 weeks</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Exclusions</h2>
						</div>
						<div class="col-md-6">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>Accidental death or injury occurring outside the normal working hours of the employee;</li>
									<li><span class="fa fa-chevron-right"></span>
										Accident or disease sustained outside the Geographical Area by any employee whose contract of service is not made in Kenya and not subject to the Laws of Kenya;
									</li>
									<li><span class="fa fa-chevron-right"></span>Accident or disease sustained by any employee who is below the age of sixteen years.</li>
									<li><span class="fa fa-chevron-right"></span>Injury caused or contributed by deliberate and wilful misconduct of the employee.</li>
									<li><span class="fa fa-chevron-right"></span>Liability arising out of any court proceedings;</li>
									<li><span class="fa fa-chevron-right"></span>Pre-existing medical conditions unless the same had been declared.</li>
								</ul>
							</div>
						</div>
						<div class="col-md-6">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>Any business or occupation other than that described in the Schedule</li>
									<li><span class="fa fa-chevron-right"></span>Accident or disease attributable to war invasion act of foreign</li>
									<li><span class="fa fa-chevron-right"></span>Civil war mutiny rebellion revolution insurrection or military or usurped power.</li>
									<li><span class="fa fa-chevron-right"></span>Claims arising due to pneumoconiosis, asbestosis, silicosis and byssinosis</li>
									<li><span class="fa fa-chevron-right"></span>Liability attaching by virtue of agreement</li>
									<li><span class="fa fa-chevron-right"></span>Political risks exclusion</li>
									<li><span class="fa fa-chevron-right"></span>Terrorism exclusion</li>
									<li><span class="fa fa-chevron-right"></span>Total asbestos exclusion</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: 'BurglaryComponent',
		components : { MainComponent, BreadCrumb },
		data () {
			return {
				cover : "Work Injury Benefit Insurance"
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
