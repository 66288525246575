<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    watch:{
		$route (to){
            const paths = ['/', '/about-us','/services'];
                if(paths.includes(to.fullPath) || to.hash){
                    return location.reload()
                }
		}
    },
};
</script>
<style>
.spanned {
    font-size: 16px;
    line-height: 35px;
    color: #989898;
}
</style>
