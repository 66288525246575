<template>
  <div>
    <HeaderComponent/>
    <div class="clear"></div>
    <slot></slot>
    <div class="clear"></div>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from '@/components/partials/HeaderComponent'
import FooterComponent from '@/components/partials/FooterComponent'
export default {

  name: 'HomeComponent',
  components : { HeaderComponent, FooterComponent },
}
</script>

<style lang="css" scoped>
</style>
