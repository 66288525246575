<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>About Us</bread-crumb>
			<section class="wwa">
				<div class="container">
					<div class="row">
						<div class="col-md-offset-2 col-md-8 col-md-offset-2">
							<h2>Who We Are</h2>
<!--							<h4>We are One of the Leading Providers of General Insurance</h4>-->
							<p>Omnisure is an established Insurance Agency specializing in Business Insurance, Personal Insurance, Health Insurance, Motor and other General Insurance services.</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="mis-vis">
								<span class="fa fa-bullseye"></span>
								<h4>Our Mission</h4>
								<p>We offer excellent advisory services on risk and offer bespoke solutions to optimize risk management.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="mis-vis">
								<span class="fa fa-eye"></span>
								<h4>Our Vision</h4>
								<p>We strive to offer the most appropriate solutions at competitive prices from renowned insurers.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="services">
				<h2 class="text-center">What Makes Us Unique</h2>
				<div class="container">
					<div class="row text-center">
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-users"></span>
							<h3>Experienced professionals</h3>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-skyatlas"></span>
							<h3>Wide Network of providers</h3>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-clock-o"></span>
							<h3>Available for support</h3>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-shield"></span>
							<h3>We Uphold Client Privacy</h3>
						</div>
					</div>
				</div>
			</section>
			<PartnersComponent/>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import PartnersComponent from '@/components/partials/PartnersComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb.vue'
	export default {
		name: 'AboutUsComponent',
		components : { PartnersComponent, MainComponent,BreadCrumb },
	}
</script>

<style lang="css" scoped>
</style>
