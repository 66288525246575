<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Employer's Liability</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion">
										<i class="fa fa-users"></i>
									</span>
									Employer's Liability Insurance
								</h4>
								<p>
									This policy offers protection to employers for their legal liability (under Common Law),
									for damages and claimant’s costs and expenses of lawsuits filed by an employee.
									The Liability would arise if an employee in the insured’s immediate service dies or is injured out of or in the course of their work,
									through any perceived negligence on the employer’s part.
									<br />The policy also reimburses the insured the expenses they may incur in the course of defending any such action/lawsuit.
									<br />
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default">Get Free Quote</router-link>
								</p>
							</div>
						</div>
						<img src="products/employer-liablity.jpeg" alt class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Employer's Liability</h2>
							<p>
								It is important to note that the employee reserves the right to sue the employer for negligence despite having been compensated under the Workmen’s
								Compensation (Act) or a Group Personal Accident policies for a work related injury or accident.
								<br />Therefore, Employers’ liability Insurance protects the employer or business against claims for compensation from employees.
								An employee may claim against the employer if they are hurt or injured whilst working for that employer.
								<br />Former employees can also claim against former employer if they believe they have become ill as a result of working for you.
								<br />
							</p>
							<div class="row">
								<h2>Scope of the Cover.</h2>
								<div class="col-md-10">
									<ul>
										<li>
											<span class="fa fa-chevron-right"></span>Third party property damage
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Personal injury
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Legal or medical fees may also be covered over is usually arranged
											on the basis of the employee’s earnings.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Exclusions</h2>
							<h4>
								No claim for a benefit in terms of this policy will be admitted by the Insurer if death, disability or temporary total disability
								arises directly or indirectly from
							</h4>
						</div>
						<div class="col-md-12">
							<ul>
								<li>
									<span class="fa fa-chevron-right"></span>
									War, invasion, act of foreign enemy, hostilities or warlike operations (whether war be declared or not). Or,
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Active participation in Mutiny, riot, strikes, military or popular uprising, insurrection, rebellion,
									revolution, military or usurped power, martial law or stage of siege or any of the events or causes which determine the proclamation
									or maintenance of martial law or stage of siege.
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Permanent or temporary dispossession resulting from confiscation, commandeering or requisition by any lawfully constituted authority.
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Any act of terrorism, regardless of any other cause contributing concurrently or in any other sequence to the loss, damage or
									expense. For the purpose of the exclusion, terrorism means an act of violence or an act dangerous to human life, tangible or
									intangible property or infrastructure with the intention or effect to influence any government or to put the public or any
									section of the public in fear. In any action suit or other proceedings where the insurer alleges that by reason of this
									definition a loss, damage or expense is not covered by this policy, the burden of proving that such loss, damage or expense
									is covered shall be upon the insured.
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Whilst any member is travelling by air other than as a passenger and not as a member of
									the crew or for the purpose of any trade of technical operation therein or thereon: or
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									By his suicide or intentional self injury
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Caused solely by an existing physical defect or other infirmity of such person
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									As a result of the influence of alcohol, drugs or narcotics upon such person unless administered by or
									prescribed by and taken in accordance with the instructions of the medical profession (other than himself);
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									In the case of females, directly or indirectly resulting from or prolonged or
									accelerated by or attributed to pregnancy, childbirth, abortion, miscarriage, obstetrical procedures or any sequelae thereof;
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									While he is, or as a result of his, engaging in
									<br />(i) Motor cycling (whether as a driver or passenger) other than on the business of the insured or the motorcycle is less than 250cc engine capacity
									<br />(ii) Racing of any kind involving the use of any power driven Vehicle, Vessel or Craft
									<br />(iii) Mountaineering necessitating the use of ropes, winter sports involving snow or ice, polo on horseback, steeple chasing, professional
									football or hang-gliding, sky-diving, bunghi jumping, or any other sport involving unnecessary risk-taking.
									<br />
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: "EmployersLiabilityComponent",
		components: { MainComponent, BreadCrumb },
		data() {
			return {
				cover: "Employer's Liability Insurance",
			};
		},
	};
</script>

<style lang="css" scoped>
</style>
