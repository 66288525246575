<template>
    <MainComponent>
        <div class="clear"></div>
        <div id="page-content">
            <section class="breadcrumb">
                <div class="container">
                    <h2>
                        <b class="text-danger">Whoops!</b>You seemed to lost
                    </h2>
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>>
                        </li>
                        <li>
                            <a href="#">Page not found</a>
                        </li>
                    </ul>
                </div>
            </section>
            <section class="error404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-sm-offset-1 col-lg-4 col-lg-offset-2">
                            <div class="page-error-box">
                                404
                                <span>
                                    <i class="ti-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-5 col-sm-offset-1 col-lg-4">
                            <div class="error-txt">
                                <p>Page Not Found</p>
                                <h2>The page you are trying to access doesn't exist.</h2>
                                <router-link to="/" class="btn-default">Go Home</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="clear"></div>
    </MainComponent>
</template>

<script>
import MainComponent from "@/components/layouts/MainComponent";
export default {
    name: "NotFoundComponent",
    components: { MainComponent },
};
</script>

<style>
</style>