<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Personl Accident</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-warning"></i></span>
									Personal Accident
								</h4>
								<p>
									The policy covers insured Persons on sustaining bodily injury caused solely and directly by accidental, violent,
									external and visible means which injury result in disablement or death.
									The policy can be arranged by an individual or corporate (a group of people with a common interest).
									The value of cover is based on your earnings salary or on a pre-agreed value
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/personal-accident.jpeg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>The Main Benefits covered are:</h2>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>
											<b>Accidental Death</b>, where the policy pays the capital benefit for loss of life arising from an accidental injury.</li>
											<li><span class="fa fa-chevron-right"></span>
												<b>Permanent Total Disablement</b> is the complete and irrecoverable loss of use of limbs or organ as a result of injury.
											This loss can be either partial or complete.</li>
											<li><span class="fa fa-chevron-right"></span>
												<b>Temporary Total Disablement</b> is disablement that totally prevents the Insured Person from attending
											in any way to his/her normal occupation. The cover replaces lost actual weekly earnings up to a maximum of 104 weeks.</li>
											<li><span class="fa fa-chevron-right"></span>
												<b>Accidental Medical Expenses</b> are costs incurred, expended and authorized by a qualified medical practitioner
											resulting from an accident up to the limit specified in the policy.</li>
											<li><span class="fa fa-chevron-right"></span>
												<b>Artificial Limbs </b>is paid out to cater for costs of prostheses or artificial limbs on the prescription of a doctor following dismemberment.
											</li>
											<li><span class="fa fa-chevron-right"></span>
												<b>Last Expense benefit</b> is paid out to cater for funeral expenses following the death of an insured person. Usually,
												this is paid out within 48 hours of receipt of a documented claim
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</MainComponent>
	</template>

	<script>
		import MainComponent from "@/components/layouts/MainComponent";
		import BreadCrumb from '@/components/partials/BreadCrumb';
		export default {
			name: "PersonalAccidentComponent",
			components : { MainComponent, BreadCrumb },
			data() {
				return {
					cover : "Personal Accident Insurance"
				}
			}
		}
	</script>

	<style scoped>

</style>
