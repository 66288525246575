<template>
	<header>
		<div class="top-bar">
			<div class="container">
				<div class="row">
					<div class="col-sm-6 address">
						<!-- <i class="ti-location-pin"></i> -->
						<!-- Liberty House, Nyerere Road; Nairobi -->
					</div>
					<div class="col-sm-6 social">
						<ul>
							<li>
								<a href="https://www.linkedin.com/in/omnisure-agency-495901210/">
									<i class="fa fa-linkedin-square"></i>
								</a>
							</li>
							<li>
								<a href="#">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#">
									<i class="fa fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="#">
									<i class="fa fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<nav class="navbar navbar-custom navbar-fixed-top" role="navigation">
			<div class="container">
				<div class="navbar-header" style="padding-bottom: 1.5rem">
					<button type="button" class="navbar-toggle" @click="toggle">
						<i class="fa fa-bars"></i>
					</button>
					<router-link to="/" class="navbar-brand">
						<img src="/img/logo.svg" alt width="200px" height="80px" style="margin-top: -3rem" />
					</router-link>
					<p style="padding-top: 5px">
						Call Us Now
						<b>+254 207 867 435</b>
					</p>
				</div>
				<div class="collapse navbar-collapse" :class="hit ? 'in' : ''">
					<ul class="nav navbar-nav navbar-right">
						<li @click="toggle">
							<router-link to="/">Home</router-link>
						</li>
						<li @click="toggle">
							<router-link to="about-us">About Us</router-link>
						</li>
						<li @click="toggle">
							<router-link to="/services">Services</router-link>
						</li>
						<li @click="toggle">
							<router-link to="/our-products">Products</router-link>
						</li>
						<li @click="toggle">
							<router-link @click="toggle" to="/contact-us">Contacts</router-link>
						</li>
						<li @click="toggle">
							<router-link to="/faq">FAQ</router-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<router-view />
	</header>
</template>

<script>
export default {
	name: "HeaderComponent",
	data() {
		return {
			hit: false,
		};
	},
	methods: {
		toggle() {
			this.hit = !this.hit;
		},
	},
};
</script>

<style lang="css" scoped>
</style>
