<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Health Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-heartbeat"></i></span>
									Health Insurance
								</h4>
								<p>
									Our services focus on all-inclusive healthcare solutions and support. We also offer specialised consulting services for all clients as below:
									<br>
									1. Individual Medical Cover <br>
									2. Family Medical Plans <br>
									3. Small & Medium Enterprises (SME) <br>
									4. Corporate Medical Schemes. <br>
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/individual-health-insurance-policy-and-stethoscope.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Health Insurance</h2>
							<p>
								Our approach to healthcare consulting follows the higher purpose of helping people attain peace of mind by
								protecting their financial and human capital assets. <br>
								We are committed to ensure our members have a suitable health insurance plan with the right benefits
								and appropriate service levels in place. We support this by adopting an all-inclusive approach to health risk management
								by incorporating the input of our clients, the insurance company and our in-house expertise
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Our key service Offerings are:</h2>
							<ul>
								<li><span class="fa fa-chevron-right"></span>Guiding our clients to choose the appropriate medical insurance solution for them</li>
								<li><span class="fa fa-chevron-right"></span>Guiding members to choose the most appropriate options within these schemes</li>
								<li><span class="fa fa-chevron-right"></span> Helping members understand their benefits, rights and processes of the scheme</li>
								<li><span class="fa fa-chevron-right"></span> Supporting our members in dealing with the scheme to resolve any frustrations</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h2>It Covers </h2>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Inpatient</li>
										<li><span class="fa fa-chevron-right"></span> Outpatient</li>
										<li><span class="fa fa-chevron-right"></span> Maternity</li>
										<li><span class="fa fa-chevron-right"></span>Optical</li>
										<li><span class="fa fa-chevron-right"></span> Dental</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		name: "HealthComponent",
		components : { MainComponent, BreadCrumb },
		data() {
			return {
				cover : "Health Insurance"
			}
		}
	}
</script>

<style scoped>

</style>
