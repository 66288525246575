<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Trade Credit Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-line-chart"></i></span>
									Trade Credit Insurance
								</h4>
								<p>
									Trade Credit Insurance is a very effective instrument that not only transfers the risk inherent in trade receivables,
									but also outsources the credit risk management process to a specialized partner,
									taking advantage of the necessary expertise and resources to effectively manage the portfolio of buyers.
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/manager-using-automotive-telematics-for-monitoring.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Trade Credit Insurance</h2>
							<h4>Available Cover Options</h4>
							<p>
								Three options available under Trade Credit Insurance.
							</p>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>
										Whole Turnover Insurance – Insurance on the entire debtor book.</li>
										<li><span class="fa fa-chevron-right"></span>
										Top Tier cover – Insurance on the top buyers of the company. This is based on the “Pareto Principle” that 20% of debtors account for 80% of turnover.</li>
										<li><span class="fa fa-chevron-right"></span>
											Single debtor cover – Insurance on a single debtor. In-depth assessment of
										the proposed buyer vis a vis the company operations is crucial to ensure no adverse selection against the insurer.</li>
									</ul>
								</div>
							</div>
							<br>
							<div class="row">
								<h4>Covered Risks</h4>
								<p>
									The risks covered under the Policy are:
								</p>
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Insolvency</li>
										<li><span class="fa fa-chevron-right"></span>Protracted Default</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="row">
							<div class="col-sm-12">
								<h2>Benefits of Trade Credit Insurance</h2>
								<h5><span>Safer business growth</span></h5>
								<p>
									Trade credit insurance gives its insured the confidence to develop their business and to explore new markets.
									Whether increasing credit line with existing customers, or extending credit terms to new clients,
									trade receivable protection provides a simple and efficient way to do so with security and peace of mind.
									Trade Credit insurance would enable a company to relax existing credit conditions bringing about competitiveness and eventually more trade.
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12">
								<h5><span>Increased borrowing</span></h5>
								<p>
									With Trade credit insurance, businesses can gain access to better financing options as lenders are generally
									sensitive to the additional security that it provides. In some cases, lenders will even require trade credit
									insurance in place before agreeing to give a loan.
									Additionally, insured trade receivables can be pledged as collateral and assigned to the bank in order to achieve better borrowing conditions.
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12">
								<h5><span>Protecting the Balance Sheet</span></h5>
								<p>
									Trade Credit Insurance serves the key role of protecting the company against bad debts.
									This has the ultimate effect of enhancing the bottom line as the losses emanating from bad debts written off are minimized.
									The credit risk, when borne by a non-specialized company constitutes a substantial risk to its balance sheet: trade receivables
									represent often up to 30% of the total assets of the non-financial companies and non-payment can therefore have drastic effects.
									Trade Credit Insurance protects against potential bad debt losses, which would affect the profit and financial strength of the seller/ insured.
									The policy holder can then significantly reduce bad debt reserves, thus improving earnings, shareholder equity and financial ratios.
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12">
								<h5><span>Customer information</span></h5>
								<p>
									Implementing a trade credit insurance program with a credit insurer, means more than protecting trade receivables.
									It means partnering with a credit risk management expert whose goal is to avoid credit losses and to support recoveries
									when they do happen. Trade credit insurance can provide valuable market intelligence on the financial
									viability of customers and, in the case of buyers in foreign countries, on any peculiar trading risks.
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<h2>Exclusions</h2>
						<h4>
							In addition to the terms set out in the General Conditions of Insurance, we shall in no case be liable for the
							payment of a Claim for any Loss which is caused by or contributed to by or arising out of
						</h4>
						<h3></h3>
						<div class="col-sm-12 benifit">
							<ul>
								<li><span class="fa fa-chevron-right"></span>
									Any chemical, biological, bio-chemical or electromagnetic weapon.
								</li>
								<li><span class="fa fa-chevron-right"></span>
									Any weapon of war employing atomic or nuclear fission and/or fusion or other like reaction or radioactive force or matter;
								</li>
								<li><span class="fa fa-chevron-right"></span>
								Ionising radiations or contamination by radioactivity from any nuclear fuel or from any nuclear waste or from the combustion of nuclear fuel;</li>
								<li><span class="fa fa-chevron-right"></span>
									The radioactive, toxic, explosive or other hazardous or contaminating properties of any nuclear installation reactor or other nuclear assembly or nuclear component thereof;
								</li>
								<li><span class="fa fa-chevron-right"></span>
									The radioactive, toxic, explosive or other hazardous or contaminating properties of any radioactive matter. The exclusion in this sub-clause does not extend to radioactive isotopes, other than nuclear fuel, when such isotopes are being prepared, carried, stored or used for commercial, agricultural medical, scientific or other similar peaceful purposes; or
								</li>
								<li><span class="fa fa-chevron-right"></span>
									Any chemical, biological, bio-chemical or electromagnetic weapon.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: "TradeComponent",
		components : { MainComponent,BreadCrumb },
		data() {
			return {
				cover : "Trade Credit Insurance"
			}
		}
	}
</script>

<style scoped>

</style>
