<template>
    <MainComponent>
        <div id="page-content">
            <bread-crumb>Frequent Asked Questions</bread-crumb>           
            <section class="accordion">
                <div class="container">
                    <div class="row">
                        <div
                            class="accordion-section"
                            v-for="(faq, index) in faqs"
                            :key="index"
                        >
                            <Question
                                :faq="faq['cover']"
                                @selected="toggling"
                            ></Question>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </MainComponent>
</template>
<script>
import MainComponent from "@/components/layouts/MainComponent";
import Question from "@/components/layouts/Question";
import BreadCrumb from "@/components/partials/BreadCrumb";
import axios from "axios";
export default {
    name: "FaqComponent",
    components: { Question, MainComponent, BreadCrumb },
    data() {
        return {
            faqs: [],
        };
    },
    methods: {
        loadData() {
            var app = this;
            app.faqs = [];
            axios("/faq.json").then(function (response) {
                const faqs = response.data;
                app.faqs = faqs;
            });
        },
        toggling(id) {
            this.faqs.forEach((faq) => {
                Object.values	(faq["cover"]).forEach((f) => {
                    f.forEach((d) => {
                        if (id === d.id) d.active = !d.active;
                        else d.active = false;
                    });
                });
            });
        },
    },
    created() {
        this.loadData();
    },
};
</script>
