<template>
	<MainComponent>
		<div class="clear"></div>
		<div id="page-content">
			<section class="flexslider">
				<ul class="slides">
					<li>
						<img src="/image/slider-1.jpeg"/>
						<div class="slide-info">
							<div class="slide-con">
								<b>Medical</b>
								<h3>Medical Insurance</h3>
								<p>
									We advise on Health Insurance Plans and arrange flexible, affordable coverage for Employees, Individuals and Families.
								</p>
								<router-link to="health-insurance" class="ti-arrow-right"></router-link>
							</div>
						</div>
					</li>
					<li>
						<img src="/image/slider-2.jpeg" />
						<div class="slide-info">
							<div class="slide-con">
								<b>Personal</b>
								<h3>Personal Insurance</h3>
								<p>Your needs are unique. <br />Get flexible Home Insurance, Personal Accident cover and Travel Insurance with Omnisure.
								</p>
								<router-link to="our-products" class="ti-arrow-right"></router-link>
							</div>
						</div>
					</li>
					<li>
						<img src="/image/slider-3.jpeg" />
						<div class="slide-info">
							<div class="slide-con">
								<b>Business</b>
								<h3>Business Covers</h3>
								<p>
									We manage your Business Insurance needs in a
									cost effective and efficient manner. We
									navigate the small print to give you the
									best solutions.
								</p>
								<router-link to="our-products#products" class="ti-arrow-right"></router-link>
							</div>
						</div>
					</li>
					<li>
						<img src="/image/slider-4.jpeg" />
						<div class="slide-info">
							<div class="slide-con">
								<b>Motor</b>
								<h3>Motor Insurance</h3>
								<p>
									From Private Cars to Trucks, Vans, Trailers
									and Motorcycles, we arrange covers for
									Personal, Commercial, Taxis and Tour
									vehicles.
								</p>
								<router-link to="motors" href="#" class="ti-arrow-right"></router-link>
							</div>
						</div>
					</li>
				</ul>
			</section>
			<div class="container">
				<div class="row">
					<section class="col-sm-7 col-md-8 col-lg-8">
						<div class="intro">
							<h2>Welcome to Omnisure</h2>
							<p>
								Omnisure is an established Insurance Agency
								specializing in Business Insurance, Personal
								Insurance, Health Insurance, Motor and other
								General Insurance services.
							</p>
							<p>
								We offer excellent advisory services on risk and
								offer bespoke solutions to optimize risk
								management. Our team comprises of highly trained
								and experienced staff to ensure our clients
								receive excellent service, advice and value.
							</p>
							<p>
								Omnisure strives to offer the most appropriate
								solutions at competitive prices from renowned
								insurers.
							</p>
							<ul class="row text-center">
								<li class="col-sm-4">
									<i class="fa fa-clock-o"></i>
									<h3>Prompt and Personalized Customer Support</h3>
								</li>
								<li class="col-sm-4">
									<i class="ti-marker-alt"></i>
									<h3>We handle your documentation</h3>
								</li>
								<li class="col-sm-4">
									<i class="fa fa-money"></i>
									<h3>Easy payment options</h3>
								</li>
							</ul>
						</div>
					</section>
					<section class="col-sm-5 col-md-4 col-lg-4">
						<div class="get-Quote-form">
							<h2>Get a free Quote</h2>
							<form class="contact-form" :class="{ 'text-danger': $v.form.$error }"  @submit.prevent="getQuote" ref="myForm">
								<div>
									<input type="text" name="user_name" class="form__input"  v-model.trim="$v.form.user_name.$model"  placeholder="Your Name"/>
									<div class="error" v-if="$v.form.user_name.$error">Kindly indicate Name</div>
								</div>
								<div>
									<input type="text" name="user_email" class="form__input" v-model.trim="$v.form.user_email.$model" placeholder="Email"/>
									<div class="error" v-if="$v.form.user_email.$error">Kindly indicate Email </div>
								</div>
								<div>
									<input type="text" name="user_phone" class="form__input" v-model.trim="$v.form.user_phone.$model" placeholder="Phone no"/>
									<div class="error" v-if="$v.form.user_phone.$error">Kindly indicate Mobile </div>
								</div>
								<div class="form-select">
									<span></span>
									<select v-model="form.user_topic" name="user_topic">
										<option selected disabled value="">Cover Type</option>
										<option>Business Insurance</option>
										<option>Medical Insurance</option>
										<option>Motor Insurance</option>
										<option>Personal Insurance</option>
									</select>
								</div>
								<div>
									<textarea rows="1" cols="1" class="form__input" v-model="$v.form.message.$model" name="message" placeholder="Message"></textarea>
									<div class="error" v-if="$v.form.message.$error"> Kindly indicate message </div>
								</div>
								<p class="typo__p alert-success" v-if="submitStatus === 'OK'"> Thanks for your enquiry! </p>
								<p class="typo__p" v-if="submitStatus === 'PENDING'" > Sending... </p>
								<div class="text-center" id="products">
									<input type="submit" class="btn-default" :disabled="submitStatus === 'PENDING'" value="Get Free Quote"/>
								</div>
							</form>
						</div>
					</section>
				</div>
			</div>
			<section class="product-tab" style="margin-bottom: -4rem;">
				<div class="container">
					<div class="row">
						<div id="parentVerticalTab">
							<h2>Products</h2>
							<ul class="resp-tabs-list hor_1 col-sm-3 col-md-3 col-lg-3">
								<li>
									<i class="fa fa-user-o"></i> Personal Insurance
								</li>
								<li>
									<i class="fa fa-line-chart"></i> Business Insurance
								</li>
								<li>
									<i class="ti-heart-broken"></i> Medical Insurance
								</li>
								<li><i class="ti-car"></i> Motor Insurance</li>
							</ul>
							<div class="col-sm-5 col-md-5 col-lg-5 resp-tabs-container hor_1">
								<div>
									<div class="prod-tab-content">
										<h4>
											<span class="prod-cion"><i class="fa fa-user-o"></i></span> Personal Insurance
										</h4>
										<p>
											<router-link to="/domestic" >Domestic Package</router-link>
										</p>
										<p>
											<router-link to="/personal-accident">Personal Accident</router-link>
										</p>
										<p>
											<router-link to="/travel-package">Travel Insurance</router-link>
										</p>
										<p>
											<router-link to="/health-insurance">Health Insurance</router-link>
										</p>
										<p class="tel">
											<i class="fa fa-phone"></i> +254 207 867 435
										</p>
										<p>
											<router-link :to="{name : 'QuoteComponent', params :  { cover:personal }}" class="btn-default"> Get Free Quote </router-link>
										</p>
									</div>
									<img src="products/portrait-of-black-family-with-kids-relax-on-couch.jpg" alt="" class="img-responsive"/>
								</div>
								<div>
									<div class="prod-tab-content">
										<h4> <span class="prod-cion"><i class="fa fa-line-chart"></i></span>Business Insurance</h4>
										<p>
											<router-link to="/fire-and-peril">Fire and Perils</router-link>
										</p>
										<p>
											<router-link to="/fire-and-peril">Burglary and Theft</router-link>
										</p>
										<p>
											<router-link to="/professional-indemnity">Professional Indemnity</router-link>
										</p>
										<p>
											<router-link to="/trade">Trade Credit Insurance</router-link>
										</p>
										<p>
											<router-link to="/wiba">Work Injury (WIBA) & Employee Benefits</router-link>
										</p>
										<p class="tel">
											<i class="fa fa-phone"></i> +254 207 867 435
										</p>
										<p>
											<router-link :to="{name : 'QuoteComponent', params :  { cover:business }}" class="btn-default"> Get Free Quote </router-link>
										</p>
									</div>
									<img src="products/business.jpeg" alt="" class="img-responsive"/>
								</div>
								<div>
									<div class="prod-tab-content">
										<h4><span class="prod-cion"><i class="ti-heart-broken"></i></span>Medical Insurance</h4>
										<p>
											<router-link to="/health-insurance">Health Insurance</router-link>
										</p>
										<p>
											<router-link to="/health-insurance">Family Medical Covers</router-link>
										</p>
										<p>
											<router-link to="/health-insurance">SME Medical Plans</router-link>
										</p>
										<p>
											<router-link to="/health-insurance">Corporate Medical Cover</router-link>
										</p>
										<p class="tel">
											<i class="fa fa-phone"></i> +254 207 867 435
										</p>
										<p>
											<router-link :to="{name : 'QuoteComponent', params :  { cover:medical }}" class="btn-default"> Get Free Quote </router-link>
										</p>
									</div>
									<img src="products/individual-health-insurance-policy-and-stethoscope.jpg" class="img-responsive"/>
								</div>
								<div>
									<div class="prod-tab-content">
										<h4><span class="prod-cion"><i class="ti-car"></i></span>Motor Insurance</h4>
										<p>
											<router-link to="/motors">Private Cars</router-link>
										</p>
										<p>
											<router-link to="/motors">Motor Commercial</router-link>
										</p>
										<p>
											<router-link to="/motors">PSV Motor Insurance</router-link>
										</p>
										<p>
											<router-link to="/motors">Motor Special Types</router-link>
										</p>
										<p class="tel">
											<i class="fa fa-phone"></i> +254 207 867 435</p>
											<p>
												<router-link :to="{name : 'QuoteComponent', params : { cover : motor }}" class="btn-default"> Get Free Quote </router-link>>
											</p>
										</div>
										<img src="products/two-cars-accident-crashed-cars-a-red-van-against-a-silver-sedan.jpg" alt="" class="img-responsive"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div class="clear"></div>
		</MainComponent>
	</template>

	<script>
		import MainComponent from "@/components/layouts/MainComponent";
		import { required, email, minLength, maxLength, numeric } from "vuelidate/lib/validators";
		import emailjs from "emailjs-com";
		export default {
			name: "HomeComponent",
			components: { MainComponent },
			data() {
				return {
					form: {
						user_name: "",
						message: "",
						user_phone: "",
						user_email: "",
						user_topic: ""
					},
					business : "Business Insurance",
					motor : "Motor Insurance",
					personal : "Personal Insurance",
					medical : "Medical Insurance",
					submitStatus: null,
				};
			},
			validations: {
				form: {
					user_name: { required },
					user_email: { required, email },
					user_phone: { required, numeric, maxLength: maxLength(10), minLength: minLength(10) },
					message: { required },
				},
			},
			methods: {
				getQuote() {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.submitStatus = "ERROR";
					} else {
						this.sendMail()
						this.submitStatus = "PENDING";
						setTimeout(() => this.submitStatus = "OK", 5000);
					}
				},
				sendMail() {
					emailjs.sendForm('service_m2sggu5',
						'template_zv6jfsb', this.$refs.myForm, 'user_SKkEJ4eBt9LAYaU3CHDYo')
					.then((result) => {
						console.log('SUCCESS!', result.status, result.text);
					}, (error) => {
						console.log('FAILED...', error);
					});
				},
			},
		};
	</script>

	<style lang="css" scoped>
</style>
