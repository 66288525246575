<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Fire and Peril Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-fire"></i></span>
									Fire and Peril Insurance
								</h4>
								<p>This policy provides indemnity for loss of or damage to the Insured person’s commercial property resulting from fire, lightning, explosion, earthquake, bush fire, spontaneous combustion, riot and strike, malicious damage, water damage and special perils</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
								<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/pallet-factory-burning-in-industrial-area.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Fire and Peril Insurance</h2>
							<h4>The contents to be covered under this policy should be one which is always in the insured location</h4>
							<p>
								To enable us to Quote for this class of insurance, we require to know the sums insured in respect of he property, as described above together, with construction type, location and occupation, such as
							</p>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Stock in trade</li>
										<li><span class="fa fa-chevron-right"></span>Buildings and Swimming Pools</li>
										<li><span class="fa fa-chevron-right"></span>Furniture, Fixtures and Fittings</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Once Contents</li>
										<li><span class="fa fa-chevron-right"></span>Plant and Machinery</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h2>Exclusions are:</h2>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Computer general loss exclusion</li>
										<li><span class="fa fa-chevron-right"></span>Consequential Loss of any kind</li>
										<li><span class="fa fa-chevron-right"></span>Political risks</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Terrorism</li>
										<li><span class="fa fa-chevron-right"></span>IT risks</li>
										<li><span class="fa fa-chevron-right"></span>War invasion act of foreign enemy</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h2>Fire Consequential Loss:</h2>
							<p>
								Consequential loss, or loss of profits is one that follows a fire loss. If a fire loss occurs it may well be a long while before the insured is fully back into operation. During such a period, the insured's earning power is diminished or drops. On the other hand, certain expenses would continue to be incurred whilst the premises are being rebuild.
							</p>
							<p>
								Such costs which constitute a claim under the consequential loss policy include:
							</p>
							<div class="row">
								<div class="col-md-10">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Rents, rates, wages of skilled staff or salaries of permanent staff</li>
										<li><span class="fa fa-chevron-right"></span>Expenses incurred in adopting emergency and temporary accommodation</li>
										<li><span class="fa fa-chevron-right"></span>Increased cost of working including hiring second hand equipment etc.</li>
										<li><span class="fa fa-chevron-right"></span>Business interruption and Loss of profits</li>
									</ul>
								</div>
							</div>
							<br>
							<p>
								The indemnity period begins with the occurrence of the damage and ends not later than the maximum period during which the results of the business shall be affected in consequence of the damage. Since this policy is consequent upon the happening of a fire, the exclusions are similar to those under the fire policy.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		components : { MainComponent, BreadCrumb },
		name: 'FireComponent',

		data () {
			return {
				cover : "Fira and Perils Insurance"
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
