<template>
  <section class="breadcrumb">
		<div class="container">
			<h2>
				<slot></slot>
			</h2>
			<ul>
				<li><router-link to="/">Home</router-link> ></li>
				<li><a href="#"><slot/></a></li>
			</ul>
		</div>
	</section>
</template>

<script>
export default {
    name: "BreadCrumbs"
}
</script>

<style>

</style>