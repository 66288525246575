<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Burglary and Theft Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-user-secret"></i></span>
									Burglary and Theft Insurance
								</h4>
								<p>
									The sums insured should reflect the current replacement or market values of the property less a deduction for wear and tear/ depreciation.
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
								<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/burglar-using-crowbar-to-break-a-home-door-at-night.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Burglary and Theft Insurance</h2>
							<p>
								To enable us to Quote for this class of insurance we require to know the sums insured in respect of the property as described above.
							</p>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Furniture, Fixtures and Fittings</li>
										<li><span class="fa fa-chevron-right"></span>Stock in trade</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Office contents</li>
										<li><span class="fa fa-chevron-right"></span>Plant and Machinery etc.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Exclusions</h2>
						</div>
						<div class="col-md-6">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>Money and securities</li>
									<li><span class="fa fa-chevron-right"></span>Property in outbuildings.</li>
									<li><span class="fa fa-chevron-right"></span>Theft by family members or employees.</li>
									<li><span class="fa fa-chevron-right"></span>Theft following fire or explosion.</li>
									<li><span class="fa fa-chevron-right"></span>Goods in the open.</li>
								</ul>
							</div>
						</div>
						<div class="col-md-6">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>Computer general loss</li>
									<li><span class="fa fa-chevron-right"></span>Political risks and Terrorism</li>
									<li><span class="fa fa-chevron-right"></span>IT risks</li>
									<li><span class="fa fa-chevron-right"></span>Any loss falling below the policy excess</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h2>Premium Indication</h2>
							<p>
								We will require to know the Full Value at Risk as well as the First Loss Sum Insured to enable us to give a conclusive rate on this class of Insurance. However our rate will be about 2.00‰ on the full value at risk.
							</p>
							<p>
								First loss sum insured implies the maximum exposure the insurer carries out of the total value at risk)
							</p>
							<p>
								Excess: 10% of each and every loss subject to the stated minimum.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: 'BurglaryComponent',
		components : { MainComponent, BreadCrumb},
		data () {
			return {
				cover : 'Burglary and Theft Insurance'
			}
		},
	}
</script>

<style lang="css" scoped>
</style>
