<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Domestic Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-home"></i></span>
									Domestic Insurance
								</h4>
								<p>
									The policy also covers the owner’s / occupier’s personal liability to third parties, for third party property damage or death/injury to third parties arising out your use of the premises
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
								<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/home-security.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Domestic Insurance</h2>
							<h4>The policy provides cover in the following:</h4>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Domestic buildings,</li>
										<li><span class="fa fa-chevron-right"></span>Electronics (TVs, Videos, Music systems etc)</li>
										<li><span class="fa fa-chevron-right"></span>Jewelry, Art, Mobile Phones, Cameras, Watches</li>
										<li><span class="fa fa-chevron-right"></span>Domestic servants (indoor and outdoor)</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Sports equipment (Golf, tennis, riding etc)</li>
										<li><span class="fa fa-chevron-right"></span>Pet insurance</li>
										<li><span class="fa fa-chevron-right"></span>Household contents (furniture, clothing, kitchen equipment cutlery etc)</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<h2>Scope of the Cover</h2>
						</div>
						<div class="col-md-10 col-sm-12">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>Fire, storm, tempest, flooding earthquake</li>
									<li><span class="fa fa-chevron-right"></span>Theft / burglary</li>
									<li><span class="fa fa-chevron-right"></span>Accidental damage</li>
									<li><span class="fa fa-chevron-right"></span>
										Pet Accident / Disease / burglary / Epidemic, emergency slaughter, transit of pet
									</li>
									<li><span class="fa fa-chevron-right"></span>Liability to third parties</li>
									<li><span class="fa fa-chevron-right"></span>
										Occupational accidents (death or illness)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<h4 style="padding-top: 20px">Cover can be arranged on buildings only, contents only or for both buildings and contents.</h4>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		name: 'DomesticComponent',
		components : { MainComponent, BreadCrumb },
		data () {
			return {
				cover : "Domestic Insurance"
			}
		}
	}
</script>
