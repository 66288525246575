<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Get Quote</bread-crumb>
			<section class="contact-us">
				<div class="container">
					<div class="row">
						<div class="col-md-8 col-md-offset-2">
							<div class="form-box get-Quote-form">
								<h2>Get Quote</h2>
								<form class="contact-form" :class="{ 'text-danger': $v.form.$error }" @submit.prevent="requestQuote" ref="Quote">
									<div class="form-content">
										<input class="form__input" type="text" v-model.trim="$v.form.user_name.$model" name="user_name" placeholder="Your Name" />
										<div class="error" v-if="$v.form.user_name.$error">Kindly indicate Name</div>
										<input class="form__input" type="text" v-model.trim="$v.form.user_email.$model" name="user_email" placeholder="Email" />
										<div class="error" v-if="$v.form.user_email.$error">Kindly indicate Email</div>
										<input class="form__input" type="text" v-model.trim="$v.form.user_topic.$model" name="user_topic" placeholder="Subject" />
										<div class="error" v-if="$v.form.user_topic.$error">Kindly indicate Subject</div>
										<input class="form__input" type="text" v-model.trim=" $v.form.user_phone.$model" name="user_phone" placeholder="Phone Number (optional)" />
										<div class="error text-danger" v-if="$v.form.user_phone.$error">Kindly indicate Valid Phone Number</div>
										<textarea rows="1" cols="1" v-model.trim=" $v.form.message.$model" name="message" placeholder="Message"></textarea>
										<div class="error" v-if="$v.form.message.$error">Kindly indicate Message</div>
										<div class="text-center">
											<input type="submit" class="btn-default" :disabled=" submitStatus === 'PENDING' " value="Submit" />
										</div>
										<p class="typo__p alert-success" v-if="submitStatus === 'OK'">Thanks for your enquiry!</p>
										<p class="typo__p" v-if="submitStatus === 'ERROR'">Kindly input missing details.</p>
										<p class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</p>
									</div>
								</form>
							</div>
							<div class="contact-info">
								<div class="col-sm-6">
									<div class="row">
										<div class="contact-address">
											<h3>Address</h3>
											<div>
												<p>
													<i class="ti-location-pin"></i>Liberty House,Nyerere Road; Nairobi
												</p>
												<p class="social">
													<a href="https://www.linkedin.com/in/omnisure-agency-495901210/" title="LinkedIn">
														<i class="fa fa-linkedin-square"></i>
													</a>
													<a href="#">
														<i class="fa fa fa-facebook"></i>
													</a>
													<a href="#">
														<i class="fa fa-twitter"></i>
													</a>
													<a href="#">
														<i class="fa fa-instagram"></i>
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="row">
										<div class="contact-dtl">
											<h3>Contact Details</h3>
											<div>
												<i class="icon fa fa-phone"></i>
												<p>+254 207 867 435</p>
											</div>
											<div>
												<i class="icon ti-email"></i>
												<p>
													<a href="#">info@omnisure.co.ke</a>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb'
	import {
		required,
		minLength,
		maxLength,
		numeric,
	} from "vuelidate/lib/validators";
	import emailjs from "emailjs-com";
	export default {
		name: "QouteComponent",
		components: { MainComponent, BreadCrumb },
		props: ["cover"],
		data() {
			return {
				form: {
					message: "",
					user_name: "",
					user_email: "",
					user_topic: this.cover,
					user_phone: "",
				},
				submitStatus: null,
				minLength: 10,
			};
		},
		validations: {
			form: {
				user_name: { required },
				user_email: { required },
				user_topic: { required },
				message: { required },
				user_phone: {
					numeric,
					minLength: minLength(10),
					maxLength: maxLength(10),
				},
			},
		},
		methods: {
			requestQuote() {
				this.$v.$touch();
				if (this.$v.$invalid) {
					this.submitStatus = "ERROR";
				} else {
					this.sendMail();
					this.submitStatus = "PENDING";
					setTimeout(() => {
						this.submitStatus = "OK";
					}, 5000);
				}
			},
			sendMail() {
				emailjs
				.sendForm(
					"service_m2sggu5",
					"template_zv6jfsb",
					this.$refs.Quote,
					"user_SKkEJ4eBt9LAYaU3CHDYo"
					)
				.then(
					(result) => {
						console.log("SUCCESS!", result.status, result.text);
					},
					(error) => {
						console.log("FAILED...", error);
					}
					);
			},
		},
		mounted() {
			if (this.cover) {
				this.form.user_subject = this.cover;
			} else {
				this.form.user_subject = this.$attrs[0];
			}
		},
	};
</script>
