<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Public Liability</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="ti-book"></i></span>
									Public Liability
								</h4>
								<p>
									Public liability insurance is designed for professionals who interact with customers or members of the public. It protects an insured person or organization against their legal liability to members of the public (third parties) arising from:
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/products-liabilty.jpeg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Public Liability</h2>
							<p>It protects an insured person or organization against their legal liability to members of the public (third parties) arising from:</p>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Death or bodily injury to third parties</li>
										<li><span class="fa fa-chevron-right"></span>Loss of or damage to third party propert</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h3></h3>
							<p>
								Arising out of and in the course of the insured’s normal business within the premises or a defined geographical location.
								The policy will also cover the costs of defending any suit brought against the insured (defence costs), in addition to any
								award that may be made against the insured by a court of law.
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10">
							<h2>Exclusions</h2>
							<div class="row">
								<div class="col-md-12">
									<div class="benifit">
										<ul>
											<li><span class="fa fa-chevron-right"></span>Liability arising out of pollution, contamination, defective drains or fumes</li>
											<li><span class="fa fa-chevron-right"></span>Terrorism Exclusion</li>
											<li><span class="fa fa-chevron-right"></span>Liability arising due to riot, strike, war, invasion, act of foreign enemy or hostilities</li>
											<li><span class="fa fa-chevron-right"></span>Liability arising out of radiation or contamination by radioactivity and nuclear waste</li>
											<li><span class="fa fa-chevron-right"></span>Asbestos Exclusion</li>
											<li><span class="fa fa-chevron-right"></span>
											Liability assumed under contract unless such liability would have attached to the Insured in the absence of such contract.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<h3>Excess-Nil</h3>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		components : { MainComponent, BreadCrumb },
		name: 'PublicLiabilityComponent',
		data () {
			return {
				cover : "Public Liability Insurance"
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
