<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Professional Indemnity</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-briefcase"></i></span>
									Professional Indemnity
								</h4>
								<p>
									This policy protects the insured against professional negligence.
									It is an important policy to have today as our society is fast developing and so
								is the consumer who is highly aware of their rights and more so, seeking compensation through litigation</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/profesional-indemnity.jpeg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Professional Indemnity</h2>
							<h4>Scope of Cover</h4>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>court judgments/awards</li>
										<li><span class="fa fa-chevron-right"></span>defence costs</li>
										<li><span class="fa fa-chevron-right"></span>fees and expenses</li>
										<li><span class="fa fa-chevron-right"></span>loss of documents</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<h3></h3>
					<div class="row">
						<div class="col-md-10">
							<h4>Cover is available for, Among other;</h4>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Lawyers</li>
										<li><span class="fa fa-chevron-right"></span>Architects</li>
										<li><span class="fa fa-chevron-right"></span>Engineers</li>
										<li><span class="fa fa-chevron-right"></span>Accountants/auditors</li>
										<li><span class="fa fa-chevron-right"></span>Trustees (Liability)</li>
										<li><span class="fa fa-chevron-right"></span>Medical (general practitioners, nurses, dentists, physiotherapists)</li>
									</ul>
								</div>
							</div>
							<h3></h3>
							<p>
								The policy protects the organization and/or directors
								against any claim brought against them for breach of professional
								duty through act of negligence, error or omission
							</p>
							<h3></h3>
							<h4>
								The policies are known under different names such as:
							</h4>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Malpractice for hospitals and doctors.</li>
										<li><span class="fa fa-chevron-right"></span>Professional indemnity for lawyers and accountants.</li>
										<li><span class="fa fa-chevron-right"></span>Trustees liabilities for trustees of pension schemes.</li>
										<li><span class="fa fa-chevron-right"></span>Directors and managers in the case of directors and managers of commercial and trading companies.</li>
										<li><span class="fa fa-chevron-right"></span> Broker’s liability for insurance brokers and stock market brokers</li>
									</ul>
								</div>
							</div>
							<div class="row">
								<h2>Policy Limits and Excesses</h2>
								<div class="col-md-12">
									<p>
										This policy has specific limits on the amount The Company will pay. All claims will be subject to an Excess where applicable.
										This means that The Company will not be liable for the first part of the claim. The amount of the Excess will be borne by the Insured.
									</p>
								</div>
							</div>
							<div class="row">
								<h2>Exclusions</h2>
								<div class="col-md-12">
									<p>
										This policy has specific limits on the amount The Company will pay. All claims will be subject to an Excess where applicable.
										This means that The Company will not be liable for the first part of the claim. The amount of the Excess will be borne by the Insured.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Scope of Cover</h2>
							<p style="padding:10px; padding-bottom: 10px">
								The Insurers shall not be liable to indemnify the Insured in respect of claims:;
							</p>
						</div>
						<div class="col-md-12">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span>
										For the costs of replacing or restoring documents;
									</li>
									<li><span class="fa fa-chevron-right"></span>
										Arising out of the death of or bodily injury to or illness or disease sustained by any person under a contract of
									employment or apprenticeship with the Insured where such death, injury, illness or disease arises out of the execution of such contract;</li>
									<li><span class="fa fa-chevron-right"></span>
										Arising from breach of contract unless such breach is a breach or alleged breach of professional duty
									by the Insured or any other person upon whom the Insured has placed reliance;</li>
									<li><span class="fa fa-chevron-right"></span>
										Arising out of loss of or distortion of computer data due to:
									</li>
									a) The presence of magnetic flux;<br>
									b) Defects in the data tapes or other data media;<br>
									c) Use or processing whilst mounted in or on any machine;<br>
									d) Wear, tear, vermin or gradual deterioration;<br>
									e) Climatic or atmospheric conditions or extremes of temperature;<br>
									<li><span class="fa fa-chevron-right"></span>
										Arising out of any property manufactured, constructed, installed, sold, supplied,
										distributed, treated, serviced, altered or repaired by or on behalf of the Insured;
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb';
	export default {
		name: "ProfessionalComponent",
		components : { MainComponent, BreadCrumb },
		data() {
			return {
				cover : "Professional Indemnity Insurance"
			}
		}
	}
</script>

<style scoped>

</style>
