<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Travel Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-plane"></i></span>
									Our Travel Insurance
								</h4>
								<p>
									Travel Insurance provides 24 hour emergency and medical assistance anywhere in the world, allowing you to enjoy easy your travel free from worrying about the high cost of medical care abroad and other risks associated with international travel
								</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/travel-flatlay.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h2>Our Travel Package</h2>
							<p>
								This cover the following:
							</p>
							<div class="row">
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Death: 96 Months earnings</li>
										<li><span class="fa fa-chevron-right"></span>Permanent Total Disability: 96 months earnings</li>
										<li><span class="fa fa-chevron-right"></span>Medical Expenses: Kshs.100,000/= per person</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Funeral Expenses: Kshs.100,000/= per person</li>
										<li><span class="fa fa-chevron-right"></span>Temporary Total Disability: Actual Weekly earnings a maximum of 52 weeks</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="columns">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h2>Key Benefits of Travel Package with Us</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<h3><span>Medical and Emergency Expenses</span></h3>
							<p>Emergency medical, surgical or dental expenses incurred during travel; Air Ambulance expenses; cost of repatriation of mortal remains or cost of funeral where death occurs. This also includes Hospital Allowance Expenses where the insured is hospitalized as a direct result of an accident or illness covered under this policy.</p>
						</div>
						<div class="col-sm-12">
							<h3><span>Travel Delay / Flight Cancellation</span></h3>
							<p>Reimburses for expenses incurred or Financial Loss if your trip is cancelled or delayed due to unavoidable circumstances.</p>
						</div>
						<div class="col-sm-12">
							<h3><span>Loss of Passport</span></h3>
							<p>Cost incurred in replacing or obtaining fresh travel documents after loss of your passport.</p>
						</div>
						<div class="col-sm-12">
							<h3><span>Loss of Baggage</span></h3>
							<p>Total loss of baggage that has been checked in by an international airline. Cost of purchasing essential items after baggage has been delayed by an airline.</p>
						</div>
						<div class="col-sm-12">
							<h3><span>Loss of Cash</span></h3>
							<p>Loss of money accidentally or by theft while on your possession, securely locked under your control or in a safety deposit box in a hotel or a bank.</p>
						</div>
						<div class="col-sm-12">
							<h3><span>Personal Legal Liability</span></h3>
							<p>This is Legal liability to third parties for their injury and / or accidental damage to their property.</p>
						</div>
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h2>Travel Insurance Options</h2>
							<h4>
								Individual Travel Insurance
							</h4>
							<ul>
								<li><span class="fa fa-chevron-right"></span>This cover is available to students travelling abroad for studies.</li>
								<li><span class="fa fa-chevron-right"></span>The cover has a high age limit of 40 Years.</li>
							</ul>
						</div>
						<div class="col-md-12">
							<h2>Corporate Travel Insurance</h2>
							<ul>
								<li><span class="fa fa-chevron-right"></span>Available companies, organizations, institutions and corporates, or persons travelling in a group.</li>
							</ul>
						</div>
						<div class="col-md-12">
							<h2>Annual Multi-trip Cover</h2>
							<ul>
								<li><span class="fa fa-chevron-right"></span>Cover for frequent travellers.</li>
								<li><span class="fa fa-chevron-right"></span>Cost effective as rates are discounted.</li>
								<li><span class="fa fa-chevron-right"></span>Flexibility to choose length per trip.</li>
								<li><span class="fa fa-chevron-right"></span>Maximum number of days per trip is 180 days.</li>
								<li><span class="fa fa-chevron-right"></span>No limit on the number of trips an insured can take per annum.</li>
							</ul>
						</div>
						<div class="col-md-12">
							<h2>Schengen Cover</h2>
							<p>
								Clients have an option of choosing a Schengen Policy when travelling to Schengen States. Some of the Schengen States Include :
							</p>
							<div class="row">
								<div class="col-md-3">
									<ul>
										<li><span class="fa fa-chevron-right"></span>France</li>
										<li><span class="fa fa-chevron-right"></span>Finland</li>
										<li><span class="fa fa-chevron-right"></span>Germany</li>
										<li><span class="fa fa-chevron-right"></span>Denmark</li>
										<li><span class="fa fa-chevron-right"></span>Netherlands</li>
									</ul>
								</div>
								<div class="col-md-3">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Portugal</li>
										<li><span class="fa fa-chevron-right"></span>Luxembourg</li>
										<li><span class="fa fa-chevron-right"></span>Italy</li>
										<li><span class="fa fa-chevron-right"></span>Spain</li>
										<li><span class="fa fa-chevron-right"></span>Sweden</li>
									</ul>
								</div>
								<div class="col-md-3">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Norway</li>
										<li><span class="fa fa-chevron-right"></span>Slovakia</li>
										<li><span class="fa fa-chevron-right"></span>Belgium</li>
										<li><span class="fa fa-chevron-right"></span>Hungary</li>
										<li><span class="fa fa-chevron-right"></span>Poland</li>
									</ul>
								</div>
								<div class="col-md-3">
									<ul>
										<li><span class="fa fa-chevron-right"></span>Greece</li>
										<li><span class="fa fa-chevron-right"></span>Austria</li>
										<li><span class="fa fa-chevron-right"></span>Latvia</li>
										<li><span class="fa fa-chevron-right"></span>Malta</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		name: 'TravelComponent',
		components : { MainComponent, BreadCrumb},
		data () {
			return {
				cover : "Travel Insurance"
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
