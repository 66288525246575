`<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Contact Us</bread-crumb>
			<section class="contact-us">
				<div class="container">
					<div class="row">
						<div class="col-md-8 col-md-offset-2">
							<div class="form-box get-Quote-form">
								<h2>Get in Touch</h2>
								<form class="contact-form" @submit.prevent="contactUs" :class="{ 'text-danger': $v.form.$error }" ref="myForm">
									<div id="get-Quote" class="form-content">
										<input class="form__input" type="text" v-model.trim="$v.form.user_name.$model" name="user_name" placeholder="Your Name"/>
										<div class="error" v-if="$v.form.user_name.$error">Kindly indicate Name</div>
										<input class="form__input" type="text" v-model.trim="$v.form.user_email.$model" name="user_email" placeholder="Email"/>
										<div class="error" v-if="$v.form.user_email.$error">Kindly indicate Email</div>
										<input class="form__input" type="text" v-model.trim="$v.form.user_phone.$model" name="user_phone" placeholder="Phone Number"/>
										<div class="error" v-if="$v.form.user_phone.$error">Kindly indicate Phone Number</div>
										<input class="form__input" type="text" v-model.trim="$v.form.user_topic.$model" name="user_topic" placeholder="Subject"/>
										<span class="error" v-if="$v.form.user_topic.$error">Kindly indicate Subject</span>
										<textarea rows="1" cols="1" v-model.trim="$v.form.message.$model" name="message" placeholder="Message"></textarea>
										<span class="error" v-if="$v.form.message.$error">Kindly indicate Message</span>
										<div class="text-center">
											<p class="typo__p alert-success" v-if="submitStatus === 'OK'">Thanks for your enquiry!</p>
											<p class="typo__p" v-if="submitStatus === 'ERROR'">Kindly input missing details</p>
											<p class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</p>

											<input type="submit" class="btn-default" :disabled="submitStatus === 'PENDING'" value="Submit"/>
										</div>
									</div>
								</form>
							</div>
							<div class="contact-info">
								<div class="col-sm-6">
									<div class="row">
										<div class="contact-address">
											<h3>Address</h3>
											<div>
												<p class="social" style="margin-left: 1px;">
													<a href="https://www.linkedin.com/in/omnisure-agency-495901210/" title="LinkedIn"><i class="fa fa-linkedin-square"></i></a>
													<a href="#"><i class="fa fa fa-facebook"></i></a>
													<a href="#"><i class="fa fa-twitter"></i></a>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="row">
										<div class="contact-dtl">
											<h3>Contact Details</h3>
											<div>
												<i class="icon fa fa-phone"></i>
												<p>+254 207 867 435</p>
											</div>
											<div>
												<i class="icon ti-email"></i>
												<p><a href="#">info@omnisure.co.ke</a>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from "@/components/partials/BreadCrumb";
	import { required, minLength, maxLength, numeric, email } from "vuelidate/lib/validators";
	import emailjs from "emailjs-com";
	export default {
		name: "ContactsComponent",
		components: { MainComponent, BreadCrumb },
		data() {
			return {
				form: {
					message: "",
					user_name: "",
					user_email: "",
					user_topic: "",
					user_phone: "",
				},
				submitStatus: null,
			};
		},
		validations: {
			form: {
				user_name: { required },
				user_email: { required, email },
				user_topic: { required },
				user_phone: { required, numeric, maxLength: maxLength(10), minLength: minLength(10)  },
				message: { required },
			},
		},
		methods: {
			contactUs() {
				this.$v.$touch();
				if (this.$v.$invalid) { this.submitStatus = "ERROR";}
				else {
					this.sendMail();
					this.submitStatus = "PENDING";
					setTimeout(() => this.submitStatus = "OK",5000);
				}
			},
			sendMail() {
				emailjs
				.sendForm("service_m2sggu5","template_giuw1cv", this.$refs.myForm, "user_SKkEJ4eBt9LAYaU3CHDYo")
				.then(
					result => console.log("SUCCESS!", result.status, result.text),
					error => console.log("FAILED...", error));
			},
		},
	};
</script>
