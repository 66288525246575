<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Motor Insurance</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion"><i class="fa fa-car"></i></span>
									Motor Insurance
								</h4>
								<p>Private Vehicles</p>
								<p>Commercial Vehicles </p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link :to="{name : 'QuoteComponent', params : { cover }}" class="btn-default"> Get Free Quote </router-link>
								</p>
							</div>
						</div>
						<img src="products/auto-accident-involving-two-cars.jpg" alt="" class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Our Motor Insurance</h2>
							<h4>Private Vehicles</h4>
							<p>These vehicles are used for social, domestic and pleasure purposes only. Examples include Saloons, Station wagons, Estates, and the SUVs.</p>

							<h4 style="padding-top: 20px">Commercial Vehicles.</h4>
							<p>These are categorised into various classes:</p>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li><span class="fa fa-chevron-right"></span><b>Own Goods</b> - These vehicles are used for the insured business, for carriage of own goods only and will exclude all usage for hire or reward e.g. taxis, matatus and any general carriage of goods for reward.</li>
										<li><span class="fa fa-chevron-right"></span><b>General Cartage</b> - These vehicles are used for the insured’s own business and are also hired out for carriage of goods for hire or reward</li>
										<li><span class="fa fa-chevron-right"></span><b>Agricultural</b>– These are generally tractors, earth movers, harvesters etc operated for farming or forestry concerns</li>
										<li><span class="fa fa-chevron-right"></span><b>Special Types</b> – These include earth movers, graders, dozers, wheel loaders, cranes, emergency response vehicles,ambulances, fire engines etc</li>
										<li><span class="fa fa-chevron-right"></span><b>Institutional</b> – These include ans and company/school buses owned and operated by institutions mainly for transportation of staff or students</li>
										<li><span class="fa fa-chevron-right"></span><b>PSV</b>– These are vehicles operated for the carriage of passengers for hire or reward e.g. taxis, “UBER,” matatus, Tour Vans, etc. They can either be hired as self-driven or chauffeur driven.</li>
										<li><span class="fa fa-chevron-right"></span><b>Motocycle</b> - Used for the insured business only</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Scope of Cover</h2>
							<p style="padding:10px; padding-bottom: 10px">
								For each category of vehicle, three types of coverage can be purchased;
							</p>
						</div>
						<div class="col-md-12">
							<div class="benifit">
								<ul>
									<li><span class="fa fa-chevron-right"></span><b>Third Party Only (TPO)</b> - This is the mandatory cover that a motorist should have on a Kenyan road. It is the most basic and minimum cover available and provides for liability to third parties only in respect of bodily injury and third party property damage.</li>
									<li><span class="fa fa-chevron-right"></span><b>Third Party Fire and Theft (TPF&T)</b> - In addition to the TPO cover above, this cover extends cover the insured’s vehicle against loss arising out of fire and theft.</li>
									<li><span class="fa fa-chevron-right"></span><b>Comprehensive</b> - This is a more enhanced cover to all the above two. In addition to Third Parties, Fire and Theft, the cover extends to any other accidental damage to the vehicles including collision, overturning, effects of floods, Earthquakes etc.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
	import MainComponent from '@/components/layouts/MainComponent'
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		components : { MainComponent, BreadCrumb },
		name: 'MotorComponent',
		data () {
			return {
				cover : "Motor Insurance"
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
