<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Contractor's All Risks</bread-crumb>
			<section class="product-main">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="prod-tab-content">
								<h4>
									<span class="prod-cion">
										<i class="fa fa-cog"></i>
									</span>
									Contractor's All Risks
								</h4>
								<p>
									A comprehensive cover available to contractors that will offer protection against destruction or damage:
									<br />1. The contract works
									<br />2. Contractor’s plant & machinery at the
									site
									<br />3. Materials on site
									<br />
								</p>
								<p>Commercial Vehicles</p>
								<p class="tel">
									<i class="fa fa-phone"></i> +254 207 867 435
								</p>
								<p>
									<router-link to="/contact-us" class="btn-default">Get Free Quote</router-link>
								</p>
							</div>
						</div>
						<img src="products/contractors-all-risk.jpg" alt class="img-responsive" />
					</div>
				</div>
			</section>
			<section class="product-detail">
				<div class="container">
					<div class="row">
						<div class="col-md-10">
							<h2>Contractor's All Risks</h2>
							<h4>Scope of the Cover.</h4>
							<div class="row">
								<div class="col-md-12">
									<ul>
										<li>
											<span class="fa fa-chevron-right"></span>Fire
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Burglary
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Flood
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Earthquake
										</li>
										<li>
											<span class="fa fa-chevron-right"></span>Unforeseen Accidental damage to the
											insured property
										</li>
									</ul>
								</div>
							</div>
							<br />
							<p>
								A limited amount will also be available for
								removal of any debris from a site after such
								damage/destruction. In addition, the policy will
								extend to cover the contractor’s liability to
								members of the public (third parties), for
								death, injuries or damage to their property
								arising out of the contractor’s negligence.
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Exclusions</h2>
						</div>
						<div class="col-md-12">
							<ul>
								<li>
									<span class="fa fa-chevron-right"></span>War
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>Terrorism
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>Consequential loss
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Defective workman ship
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Wear and tear
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>Liability to the insured’s employees
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>
									Political risk
								</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<h2>Excess Applicable on</h2>
						</div>
						<div class="col-md-12">
							<ul>
								<li>
									<span class="fa fa-chevron-right"></span>Act
									of God
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>Third party liability
								</li>
								<li>
									<span class="fa fa-chevron-right"></span>All
									other claims
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</MainComponent>
</template>

<script>
import MainComponent from "@/components/layouts/MainComponent";
import BreadCrumb from "@/components/partials/BreadCrumb";
export default {
	name: "ContractorComponent",
	components: { MainComponent,BreadCrumb },
};
</script>

<style scoped>
</style>
