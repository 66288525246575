<template>
  <section class="partners">
    <h2 class="text-center">Our Partners</h2>
    <div class="container">
      <div class="row">
        <div class="partner-slider-mon">
          <div class="col-sm-3 text-center">
            <h2>
              <b>Insurance</b> Partners
            </h2>
          </div>
          <div class="col-sm-9">
            <div class="partner-slider owl-carousel">
              <div v-for="(chunk, index) in chunkedItems" :key="index">
                <div class="partner-logo" v-for="(link, index ) in chunk" :key="index">
                  <p><img style="height:90px" :src="link" alt="Partner" /></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import chunk from 'lodash.chunk'
export default {

  name: 'PartnersComponent',

  data () {
    return {
      imageLink : [
        require("../../../public/img/apa.jpg"),
        require("../../../public/img/aig.jpg"),
        require("../../../public/img/britam.jpg"),
        require("../../../public/img/allianz.jpg"),
        require("../../../public/img/cic.jpg"),
        require("../../../public/img/gemina.jpg"),
        require("../../../public/img/jubilee.jpg"),
        require("../../../public/img/resolution.jpg"),
        require("../../../public/img/sanlam.jpg"),
        require("../../../public/img/icea_lion.jpg"),
        require("../../../public/img/uap.jpg"),
        require("../../../public/img/heritage.png"),
      ],
    }
  },
  computed: {
    chunkedItems() {
      return chunk(this.imageLink, 2)
    }
  }
}
</script>
<style lang="css" scoped>
</style>
