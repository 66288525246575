<template>
	<MainComponent>
		<div id="page-content">
			<bread-crumb>Our Services</bread-crumb>
			<section class="services">
				<h2 class="text-center" id="services">Our Services</h2>
				<div class="container">
					<div class="row text-center">
						<div class="col-sm-3 services-dtl">
							<span class="ti-book"></span>
							<h3>We explain the terms</h3>
							<p>And navigate the small print</p>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="ti-pencil"></span>
							<h3>We negotiate cover</h3>
							<p>To give you the best offer</p>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="fa fa-money"></span>
							<h3>Easy payment terms</h3>
							<p>We arrange easy payment options</p>
						</div>
						<div class="col-sm-3 services-dtl">
							<span class="ti-email"></span>
							<h3>Claims Processing</h3>
							<p>We assist to process your claim</p>
						</div>
					</div>
				</div>
				<hr />
			</section>
			<PartnersComponent />
		</div>
	</MainComponent>
</template>

<script>
	import PartnersComponent from "@/components/partials/PartnersComponent";
	import MainComponent from "@/components/layouts/MainComponent";
	import BreadCrumb from '@/components/partials/BreadCrumb'
	export default {
		name: "ServicesComponents",
		components: { MainComponent, PartnersComponent, BreadCrumb },
	};
</script>

<style scoped>
</style>
